<!-- @format -->
<script lang="ts">
    import { getStoresContext } from "~/stores";
    import type { StoresKey } from "~/stores";
    import { createEventDispatcher } from "svelte";
    import { getStorePagesFromFobs } from "../../configurations";
    export let request_store_key: StoresKey;
    export let dark_font=true;
    export let nav_item: NavigationMenuItem;

    const { site_page, i18nOf, locale, reverse_router, site_groupings, stores } = getStoresContext(request_store_key); 
    const dispatch = createEventDispatcher();
    const storesPageLink = $reverse_router.routePurpose('stores collection');
    const restaurantsPageLink = $reverse_router.routePurpose('custom1');
    $: title = i18nOf(nav_item?.name_i18n);
    $: href = nav_item?.link? $reverse_router.routePage(nav_item?.link) : nav_item?.url;
    $: target = nav_item?.link ? "_self" : "_blank";
    $: current = $site_page?.friendlyUrlPath;
    $: is_active = current == nav_item?.link;
    $: sub_pages = nav_item?.navigationMenuItems;
    $: sub_pages_filtered =  (sub_pages|| []).filter(
        (item) => !item.excluded_locales?.some((x) => x == $locale)
    );
    let show = false;
    let fontColor = 'var(--color-accent-black)';

    $: {
        fontColor = dark_font || show ? 'var(--color-accent-black)' : 'var(--color-accent-white)';
        nav_item = getStorePagesFromFobs(nav_item, $site_groupings, $stores, storesPageLink, restaurantsPageLink);
    }  
    
    function openDropdown(open: boolean ) {
        dispatch("openDropdown", open);
    }
    function focus() {
        show = true;
        openDropdown(show);
    }
    function blur() {
        show = false;
        openDropdown(show);
    }
</script>

{#if sub_pages_filtered?.length} 
    <li class="nav-item"
        class:active={is_active}
        style="--font-color:{fontColor};"
        on:mouseenter={focus} 
        on:mouseleave={blur}>
        <div class='flex-row align-items-center d-flex justify-content-between w-100'>
            <a {href} {target}> {title} </a>
            <img class:rotate={show} 
                class='{fontColor === 'var(--color-accent-white)'? 'invert-color':'revert-color'} icon d-none d-md-block d-lg-block' 
                src="/theme/ainoa2024/images/arrow-down.svg" alt="Toggle submenu"/>
        </div>
        <ul class='dropdown'>
            <a class='dropdown-title' {href} {target}>
                {title}
            </a> 
            {#each sub_pages_filtered as nav_child}
                <li class="dropdown-item">
                    <a 
                        href={nav_child?.link? $reverse_router.routePage(nav_child?.link) : nav_child?.url} 
                        target={nav_child?.link ? "_self" : "_blank"}> 
                        {i18nOf(nav_child?.name_i18n)}
                    </a>
                </li>
            {/each}
        </ul>
    </li>
{:else}
    <li class="nav-item"
        class:active={is_active} 
        style="--font-color:{fontColor};">
        <a {href} {target}>{title}</a>
    </li>
{/if}

<style lang="scss">
    .active {
        border-bottom: 2px solid var(--font-color);
    }
    .nav-item {
        a {
            text-decoration: none;
            &:hover {
                background-color: unset;
            }
        }
        display: inline-block; 
        position: relative; 
        padding: 3px 0px 14px 0;
        font-size: 14px;
        z-index: 12;
        @media screen and (max-width: 996px) {
            font-size: 13px;
        }
        a:hover{
            color: inherit;
            text-decoration: none;
        }
        &:hover {
            border-bottom: 2px solid var(--font-color);
            ul {    
                display: block;
                opacity: 1;
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
        .invert-color {
            -webkit-filter: invert(1);
            filter: invert(1);
        }
        .revert-color {
            -webkit-filter: invert(0);
            filter: invert(0);
        }
        .icon {
            width: 9px;
            height: 5px;
            transition: transform 0.5s;
            transform: rotate(180deg);
            margin: 0 0 0 4px;
        }
        .rotate {
            transform: rotate(0deg);
        }
        .dropdown {
            position: absolute;
            width: max-content;
            left: 0;
            top: 107%;
            padding: 32px 100vw 40px 100vw; 
            margin: 0 -100vw;
            z-index: 101;
            display: none;
            opacity: 0;
            background: var(--mobile-background-color);
            box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.2);
            .dropdown-title {
                display: ruby-text;
                padding: 0 0 10px 0;
                font-size: 18px;
                font-weight: bold;
                color: var(--color-accent-black);
                cursor: pointer;
                &:hover{
                    text-decoration: none;
                    color: var(--color-brand-primary);
                }
                &:after {
                    display: inline-block;
                    transform: rotate(90deg);
                    content:url("/theme/ainoa2024/images/arrow-down.svg");
                    margin: 0 0 0 12px;
                }
                &:hover:after {
                    filter: var(--filter-color-brand-primary);
                }
            }
            .dropdown-item {
                font-size: 14px;
                color: var(--color-accent-black);
                padding: 10px 0;
                &:hover {
                    text-decoration: none;
                    background-color: transparent;
                    color: var(--color-brand-primary);
                }
                &:before {
                    display: inline-block;
                    transform: rotate(90deg);
                    content:url("/theme/ainoa2024/images/arrow-down.svg");
                    margin: 0 5px 0 0;
                }
                &:hover:before {
                    filter: var(--filter-color-brand-primary);
                }
            }
        }
    }
</style>