<!-- @format -->
<script lang="ts">
  import { type Alternate } from "~/metadata";
  import type { StoresKey } from "~/stores";
  import { getStoresContext } from "~/stores";
  import { onMount } from "svelte"; 
  import { ReverseRouter } from "~/reverse-router";
  import { getFragmentConfigOr, valueGetters } from "~/components/field-utils";
  import { getOpeningHoursRangesAndStatusByDate } from "~/util/date-n-time";
  import NavItem from "./NavItem.svelte";
  import NavItemMobile from "./NavItemMobile.svelte";
  
  export let fragment: Fragment;
  export let request_store_key: StoresKey;

  const { nav_menus, reverse_router,alternate, locale, site, opening_hours, T } = getStoresContext(request_store_key);
  const stores_context = getStoresContext(request_store_key);
  const { getText } = valueGetters(stores_context);

  let mainBanner: HTMLElement | null;  
  let subpageBanner: HTMLElement | null;

  onMount(async () => {
    mainBanner = document.querySelector(".main-banner .veil");
    subpageBanner = document.querySelector(".subpage-banner .overlay-1");
    if(subpageBanner?.classList.contains('d-none')) subpageBanner = null;    
  });

  let mobileBackgroundColor = getFragmentConfigOr("mobileBackgroundColor", fragment, "var(--color-brand-primary-lighten-6)");
  let backgroundColorBurger = 'transparent'
  let backgroundColor = 'transparent';
  $: titleOne = getText("titleOne", fragment) || 'KAUPPAKESKUS';
  $: titleTwo = getText("titleTwo", fragment) || 'TAPIOLA';

  let openMobileMenu = false;
  let isDropdownOpen =  false;
  let fontColor = 'var(--color-accent-black)';
  let today: { ranges: string[]; status: string } | null;
  const languages: Record<string, string> = {
    "fi-FI": "Suomeksi",
    "en-US": "English",
  };

  $: {  
    if ($opening_hours && $locale) {
      today = getOpeningHoursRangesAndStatusByDate(
        $opening_hours.shoppingCenterOpeningHours,
        $opening_hours.exceptionalOpeningHours,
        new Date(),
        $locale,
      );
    }
    //Get the veil color from the banner as the hamburguer background default color
    if ( mainBanner || subpageBanner ) {
      let banner = mainBanner || subpageBanner;
      let style = window.getComputedStyle(banner);
      let backgroundBanner = style.getPropertyValue('background');
      backgroundColorBurger = getFragmentConfigOr('backgroundColorBurger', fragment, `${backgroundBanner.split('gradient(').pop().split(')')[0]})`);
      backgroundColor = 'transparent';
    } else {
      backgroundColorBurger = getFragmentConfigOr('backgroundColorBurger', fragment, "var(--color-brand-primary-lighten-6)");
      backgroundColor = getFragmentConfigOr('backgroundColor', fragment, 'var(--color-brand-primary)');
    }
    fontColor = backgroundColor === 'transparent' && !isDropdownOpen? 'var(--color-accent-white)' : 'var(--color-accent-black);';
  }
  let primaryFiltered: NavigationMenuItem[];
  $: primary = $nav_menus.find((nav) => nav.navigationType == "Primary");
  $: primaryFiltered = (primary?.navigationMenuItems || []).filter(
    (item) => !item.excluded_locales?.some((x) => x == $locale)
  );
  $: home_page_nav_item = $reverse_router.routePurpose("home page");
  $: logo = $site && $site.logo;
  $: opposite_lang = $locale == "fi-FI" ? "en-US" : "fi-FI";

  function openDropdown(event: CustomEvent) {
    isDropdownOpen = event.detail;
  }

  function hrefFor( locale: string,alternate: readonly Alternate[],reverse_router: Readonly<ReverseRouter>) {
    const alt = alternate.find((x) => x.locale == locale);
    return alt
      ? alt.href
      : reverse_router.findPurposeRoute("home page", locale);
  }
  $: darkFont = isDropdownOpen;
  $:t = $T;
</script>

<header style="--background-color:{backgroundColor}; 
  --burger-bckground:{backgroundColorBurger};
  --mobile-background-color:{mobileBackgroundColor};"
  class:color-open-background={isDropdownOpen}>
  <nav class="d-flex align-items-center">
    <a class="row col-12 justify-content-center align-items-center" href={home_page_nav_item}>
      <div class="{darkFont? 'dark-font':'white-font'} logo-title"> {titleOne} </div>
      <img class='{darkFont? 'revert-color':'invert-color'} logo' src={logo} alt="Ainoa logo"/>
      <div class="{darkFont? 'dark-font':'white-font'} logo-title"> {titleTwo} </div>
    </a>
    <a class="{darkFont? 'dark-font':'white-font'} d-none d-md-block position-absolute op-h header-link" href={$reverse_router.routePurpose('opening hours')}>
      {#if today && today.ranges.length}
        {t`header.todayOpen`}
        {#each today.ranges as range}
          {#each range.split("–") as o, i}
            <span>{`${o.toString().padStart(2, '0')}:00`}</span>
            {#if i == 0}
              <span>-</span>
            {/if}
          {/each}
        {/each}
      {:else}
        {t`header.todayClosed`}
      {/if}
      <img class='{ darkFont? 'revert-color':'invert-color'}' src="/theme/ainoa2024/images/arrow-down.svg" alt="">
    </a>
    <button class="burger-icon d-sm-block d-md-none" on:click={() => {
      openMobileMenu = true; }}>
      <img class="" src="/theme/ainoa2024/images/burger.svg" alt=""/>
    </button>
  </nav>
  <div class="{darkFont? 'dark-line':'white-line'} gap"></div>
  {#if primaryFiltered}
    <div class="{darkFont? 'dark-font':'white-font'} nav-items row d-none d-md-flex justify-content-between">
      <ul class="col-11 row">
        <div class="d-flex w-100 justify-content-around">
          {#each primaryFiltered as nav_item}
            <NavItem on:openDropdown={openDropdown} dark_font={darkFont} {nav_item} {request_store_key}/>
          {/each}
        </div>
      </ul>
      <a class="{darkFont? 'dark-font':'white-font'} header-link col-1 d-flex justify-content-end" title={languages[opposite_lang]}
        href={hrefFor(opposite_lang, $alternate, $reverse_router)}>
        {languages[opposite_lang]}
      </a>
    </div>
    <div class="d-flex d-md-none" class:hide={openMobileMenu}>
      <div class="{darkFont? 'dark-font':'white-font'} col-12 d-flex justify-content-between">
        {#each primaryFiltered.slice(0, 4) as nav_item}
          <NavItem dark_font={darkFont} {nav_item} {request_store_key}/>
        {/each}
      </div>
    </div>
    <div class="dropdown-mobile d-flex" class:open={openMobileMenu}>
      <div class="w-100">
          <a class="d-flex row col-12 justify-content-center align-items-center mt-3 mb-4" href={home_page_nav_item}>
            <div class="logo-title dark-font"> {titleOne} </div>
            <img class='logo revert-color' src={logo} alt="Ainoa logo"/>
            <div class="logo-title dark-font"> {titleTwo} </div>
          </a>
          <button
            class="button-close"
            on:click={() => {
            openMobileMenu = false; }}>
            <img src="/theme/ainoa2024/images/cross.svg" alt=''>
          </button>
        <div class="mobile-wrapper">
          {#each primaryFiltered as nav_item}
            <NavItemMobile {nav_item} {request_store_key}/>
          {/each}
          <a
            class="langs d-lg-none d-flex dark-font"
            title={languages[opposite_lang]}
            href={hrefFor(opposite_lang, $alternate, $reverse_router)}
            >{languages[opposite_lang]}
          </a>
        </div>
      </div>
    </div>
  {/if} 
  <div class="gap-hover dark-line" class:show={isDropdownOpen}></div>
</header>

<style lang="scss">
  header {
    a {
      text-decoration: none;
      &:hover {
        background-color: unset;
      }
    }
    position: absolute;
    width: 100%;
    z-index: 11;
    background-color: var(--background-color);
    padding: 20px 82px 0px 82px;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    @media screen and (max-width: 996px) {
      padding: 20px 0px 0px 0px;
    }
    nav {
      .op-h {
        right: 82px;
        img {
          transform: rotate(90deg);
          margin: 0 0 0 5px;
        }
        @media screen and (max-width: 996px) {
          width: 100px;
        }
      }
      .burger-icon {
        background-color: var(--burger-bckground);
        border: none;
        position: absolute;
        right: 6px;
        top: 9px;
        cursor: pointer;
        padding: 9px 10px;
        img {
          width: 25px;
        }
      }
    }
    .gap {
      margin: 20px 0 0 0;
      padding: 15px 0 0 0;
    }
    .nav-items {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      ul {
        margin-bottom: 0;
      }
    }
    .header-link {
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
          }
      }
    .logo {
      margin: 0 40px;
      width: 114px;
      height: 21px;
      @media screen and (max-width: 996px) {
        margin: 0 8px;
      }
    }
    .logo-title {
      font-family: var(--font-main);
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      color: var(--color-accent-black);
      &:hover {
        font-size: 12.2px;
      }
      @media screen and (max-width: 996px) {
        font-size: 9px;
      }
    }
      .dark-font {
        color: var(--color-accent-black);
      }
      .white-font {
        color: var(--color-accent-white);
      }
      .dark-line {
        border-top: 2px solid #334F31;
        opacity: 0.2;
      }
      .white-line {
        border-top: 2px solid var(--color-accent-white);
        opacity: 0.2;
      }
      .invert-color {
        -webkit-filter: invert(1);
        filter: invert(1);
      }
      .revert-color {
        -webkit-filter: invert(0);
        filter: invert(0);
      }
      .gap-hover {
        display: none;
        position: relative;
        z-index: 13;
      }
      .show {
        display: block;
      }
      .dropdown-mobile {
        width: 100%;
        transition: all 0.7s ease;
        top: 0;
        position: fixed;
        background-color: var(--mobile-background-color);
        height: 100vh;
        left: 100%;
        padding: 9px 6px 0 0px;
        .mobile-wrapper {
          overflow-y: auto;
          height: 100vh;
        }
        .button-close {
          position: absolute;
          top: 0;
          right: 0;
          border: none;
          background-color: var(--color-accent-white);
          padding: 13px;
          margin: 9px 6px 0 0;
        }
        .langs {
          font-size: 16px;
          padding: 15px 0 15px 24px;
        }
      }
    .open {
      left: 0;
    } 
  } 
  .color-open-background {
    background-color: var(--mobile-background-color) !important;
  }
</style>