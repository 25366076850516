<!-- @format -->
<script lang="ts">
    import { getStoresContext } from "~/stores";
    import { getStorePagesFromFobs } from "../../configurations";
    import type { StoresKey } from "~/stores";

    export let request_store_key: StoresKey;
    export let nav_item: NavigationMenuItem;

    const { site_page, i18nOf, site_groupings, stores, reverse_router } = getStoresContext(request_store_key); 

    $: title = i18nOf(nav_item?.name_i18n);
    $: href = i18nOf(nav_item.link_i18n);
    $: target = nav_item?.link ? "_self" : "_blank";
    $: current = $site_page?.friendlyUrlPath;
    $: is_active = current == nav_item?.link;
    $: sub_pages = nav_item?.navigationMenuItems;
    const storesPageLink = $reverse_router.routePurpose('stores collection');
    const restaurantsPageLink = $reverse_router.routePurpose('custom1');

    let show = false;
    $: nav_item = getStorePagesFromFobs(nav_item, $site_groupings, $stores, storesPageLink, restaurantsPageLink);
</script>

{#if nav_item?.navigationMenuItems?.length} 
    <div class="menu-item">
        <div class='d-flex justify-content-between'>
            <a {href} {target} class="title"> { title } </a>
            <!-- svelte-ignore missing-declaration -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <img class='icon'  
                class:rotate={show}
                on:click={() => (show = !show)}
                src="/theme/ainoa2024/images/arrow-down.svg" alt=""/>
        </div>
    <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div class='col-12 px-0 dropdown' class:show={show}>
            {#each sub_pages as nav_child}
                <div class="d-flex flex-row mt-3">
                    <img class='icon-before' 
                        src="/theme/ainoa2024/images/arrow-down.svg" alt=""/>
                    <a class="dropdown-item" href={$reverse_router.routePage(nav_child?.link)}> 
                        {i18nOf(nav_child?.name_i18n)}
                    </a>
                </div>
            {/each}
        </div>
    </div>
{:else}
    <a {href} {target}
        class="menu-item"
        class:active={is_active}>
            {title}
    </a>
{/if}

<style lang="scss">
    a {
        text-decoration: none;
        &:hover {
            background-color: unset;
        }
    }
    .menu-item {
        display: flex;
        flex-direction: column;
        padding: 15px 19px 15px 24px;
        border-bottom: 1px solid var(--color-accent-black);
        font-size: 14px;
        font-weight: bold;
        color: var(--color-accent-black);
        .title {
            font-weight: bold;
        }
        .icon {
            width: 9px;
            height: 5px;
            margin: 0 0 0 0px;
            transition: transform 0.5s;
            transform: rotate(180deg);
            -webkit-filter: invert(0);
            filter: invert(0);
        }
        .rotate {
            transform: rotate(0deg);
        }
        .dropdown {
            display: none;
            .icon-before {
                transform: rotate(90deg);
                margin: 0 10px 0 0;
            }
            .dropdown-item {
                font-size: 14px;
                color: var(--color-accent-black);
                padding: 0;
                &:hover {
                    background-color: transparent !important;
                }
            }
        }
        .show {
            display: block;
        }
    }
</style>